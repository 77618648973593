<template>
  <surround-box :class="`sitePane${collapse ? ' closed closedH' : ''}`">
    <div class="collapse" @click="collapseBox">
      <Icon
        :style="`transform: rotate(${collapse ? 0 : -180}deg)`"
        type="ios-arrow-back"
      />
    </div>
    <div
      :class="`collapseTitle${collapse ? ' collTitleOpen' : ''}`"
      v-if="collapse"
    >
      {{ menuName }}
    </div>
    <div class="inner">
      <a class="close" @click="() => $emit('hidden')">X</a>
      <p class="title">
        <img src="imgs/title.gif" alt="" />
        {{ menuName }}
      </p>
      <moniPane
        :currentSites="currentSites"
        :selectedSite="selectedSite"
        @showChart="showChart"
        @on-change="onChange"
      />
    </div>
  </surround-box>
</template>
<script>
import surroundBox from "@/components/mapComp/surroundBox";
import moniPane from "./pane";
export default {
  name: "sitePane",
  props: {
    menuName: {
      type: String,
      default: "",
    },

    currentSites: {
      type: Array,
      default() {
        return [];
      },
    },
    selectedSite: Object,
  },
  components: {
    surroundBox,
    moniPane,
  },
  data() {
    return {
      collapse: false,
    };
  },
  methods: {
    showChart(data) {
      this.$emit("showChart", data);
    },
    collapseBox() {
      this.collapse = !this.collapse;
    },
    onChange(site) {
      this.$emit("on-change", site);
    },
  },
};
</script>
<style lang="less" scoped>
.closed {
  width: 132px !important;
}
.closedH {
  height: 40px !important;
}
.sitePane {
  position: absolute !important;
  top: 110px;
  right: 0;
  z-index: 401;
  background: rgba(0, 0, 0, 0.5);
  // width: 460px;
  width: 440px;
  // height: 500px;
  transition: all 0.3s linear;
  overflow: hidden;
  .collapse {
    position: absolute;
    z-index: 2;
    top: 50%;
    left: 0;
    transform: translateY(-50%);
    height: 40px;
    width: 18px;
    background-color: rgba(8, 221, 221, 0.25);
    font-size: 20px;
    line-height: 40px;
    cursor: pointer;
  }
  .collapseTitle {
    position: absolute;
    left: 0;
    top: 0;
    transition: all 0.3s linear;
    width: 0;
    height: 40px;
    line-height: 40px;
    padding-left: 44px;
    background-color: #01545d;
    font-size: 16px;
    z-index: 1;
  }
  .collTitleOpen {
    width: 132px;
  }
  .inner {
    min-height: 300px;
    max-height: calc(~"100vh - 250px");
    overflow: auto;
    padding: 16px;
    &::-webkit-scrollbar {
      display: none;
    }
    .close {
      position: absolute;
      top: 10px;
      right: 15px;
      z-index: 9999;
    }

    .title {
      margin-bottom: 10px;
      color: #ffffff;
      font-size: 18px;
      display: flex;
      align-items: center;

      img {
        width: 20px;
        height: 20px;
        margin-right: 5px;
      }
    }
  }
  .title {
    margin-bottom: 10px;
    color: #ffffff;
    font-size: 18px;
    display: flex;
    align-items: center;
    img {
      width: 20px;
      height: 20px;
      margin-right: 5px;
    }
  }
  .typeTitle {
    img {
      width: 20px;
      height: 20px;
      vertical-align: middle;
      margin-right: 5px;
    }
    font-size: 14px;
    margin: 8px 0;
  }

  /deep/ .ivu-select-selection {
    background: rgba(0, 0, 0, 0.4) !important;
  }
  /deep/ .ivu-select-selection {
    border: none;
  }
  /deep/ .ivu-select-arrow {
    display: none;
  }
  /deep/ .ivu-select-visible .ivu-select-selection {
    box-shadow: none;
  }
}
</style>